import AnalyticsService from '@root/core/src/services/analytics-service';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import { constantize } from '@root/core/src/utils/strings';

export default class InterfaceActionsService {
  static buildCallToActionButton({
    interfaceActionsMap,
    button,
    trackClick = () => {},
  }) {
    if (!button) { return; }

    const {
      buttonText,
      buttonLink,
      buttonAction,
      buttonType,
      modal,
    } = button;

    const titleSlug = AnalyticsService.normalizeForAnalyticsContext(buttonText);

    const trackCTAClick = () => {
      trackClick(`CTA_BUTTON_${titleSlug}`, {
        buttonText,
        buttonLink,
        buttonAction,
      });
    };

    if (modal) {
      const ModalButton = interfaceActionsMap.idToComponentMap['openHubspotModal'];
      return () => (
        <ModalButton
          modal={modal}
          onClick={() => trackCTAClick()}
          variant={buttonType.toLowerCase()}
        >
          {buttonText}
        </ModalButton>
      );
    }

    return () => (
      <LinkButton
        onClick={() => trackCTAClick()}
        to={buttonLink}
        type={LinkButtonModel.types[constantize(buttonType)]}
      >
        {buttonText}
      </LinkButton>
    );
  }
}
