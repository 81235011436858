import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function NavLink({
  children, to, linkStyles,
}) {
  const { trackClick } = useAnalytics('NAV_LINK', false);
  const handleClick = () => {
    const linkName = typeof children === 'string' ? children.replace(/\s+/, '_') : to;
    trackClick(linkName);
  };

  return (
    <Link
      css={[styles.link, linkStyles]}
      onClick={handleClick}
      to={to}
    >
      {children}
    </Link>
  );
}

const styles = StyleSheet.create({
  link: {
    ...Theme.navLink(),
    textDecoration: 'none',
    transition: 'color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955)',
    '&:hover': {
      color: Colors.rootOrange(),
    },
    '&:focus': {
      color: Colors.rootOrange(),
    },
  },
});

NavLink.propTypes = {
  children: PropTypes.string.isRequired,
  linkStyles: PropTypes.object,
  to: PropTypes.string.isRequired,
};
