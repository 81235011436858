import AuthService from '@root/core/src/services/auth-service';
import FunnelStage from '@root/core/src/utils/funnel-stage.js';
import environment from '@root/core/src/utils/environment';
import mixpanel from 'mixpanel-browser';

const EventTypes = {
  CLICK: 'Click',
  PAGE_VIEW: 'Page View',
};

export default class AnalyticsService {
  static defaultEventPrefix = 'WEB_APP';

  static trackEvent(context, event, properties = {}) {
    if (environment.isFunnelLoggingEnabled) {
      properties.funnelStage = FunnelStage.getFunnelStage();
    }

    AnalyticsService._track([context, event], properties);
    if (environment.isFunnelLoggingEnabled) {
      AnalyticsService._track_with_additional_properties(context, event, properties);
    }
  }

  static trackViewEvent(context, properties = {}) {
    if (environment.isFunnelLoggingEnabled) {
      properties.funnelStage = FunnelStage.getFunnelStage();
    }
    AnalyticsService._track([context, 'VIEWED'], properties);
    if (environment.isFunnelLoggingEnabled) {
      AnalyticsService._track_with_additional_properties(context, EventTypes.PAGE_VIEW, properties);
    }
  }

  static trackClickEvent(context, clickContext, properties = {}) {
    if (environment.isFunnelLoggingEnabled) {
      properties.funnelStage = FunnelStage.getFunnelStage();
    }
    AnalyticsService._track([context, 'CLICKED', clickContext], properties);
    if (environment.isFunnelLoggingEnabled) {
      properties.target = clickContext;
      AnalyticsService._track_with_additional_properties(context, EventTypes.CLICK, properties);
    }
  }

  static alias(userId) {
    mixpanel.alias(userId);
  }

  static getDistinctId() {
    if (typeof window !== 'undefined') {
      AnalyticsService.initMixpanel();
      return mixpanel.get_distinct_id();
    }
    return 'some-distinct-id';
  }

  static initMixpanel(identity) {
    mixpanel.init(environment.mixpanelToken);
    if (identity) { mixpanel.identify(identity); }
  }

  static trackLinks(selector, eventName, properties = {}) {
    mixpanel.track_links(selector, eventName.toUpperCase(), properties);
  }

  static register(properties) {
    mixpanel.register(properties);
  }

  static reset() {
    mixpanel.reset();
  }

  static normalizeForAnalyticsContext(string) {
    if (!string) {
      return '';
    }

    return string
      .toLocaleUpperCase()
      .replace(/[^a-z0-9/]+/g, '_')
      .replace(/(^_|_$)+/g, '')
      .replace(/-/g, '_')
      .replace(/__+/g, '_');
  }

  static _track(contexts, { eventPrefix = AnalyticsService.defaultEventPrefix, ...properties } = {}) {
    const eventName = AnalyticsService._getEventName(contexts, eventPrefix);
    const { userId, accountId } = AuthService.getCurrentUserContext();

    AnalyticsService.initMixpanel(userId);

    mixpanel.track(eventName, {
      ...properties,
      accountId,
    });
  }

  static _track_with_additional_properties(pageName, eventType, properties) {
    const { userId, accountId } = AuthService.getCurrentUserContext();

    const derivedPageName = AnalyticsService._getPageName(pageName);

    AnalyticsService.initMixpanel(userId);

    const dupProperties = {
      ...properties,
    };
    delete dupProperties.eventPrefix;
    mixpanel.track(derivedPageName, {
      eventType,
      ...dupProperties,
      accountId,
      platform: 'Web',
    });
  }

  static _getEventName(contexts, eventPrefix) {
    return [eventPrefix].concat(...contexts).join('_').toUpperCase();
  }

  static _getPageName(pageName) {
    if (Array.isArray(pageName)) {
      return pageName[pageName.length - 1];
    } else {
      return pageName;
    }
  }
}
