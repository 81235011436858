import AnalyticsService from '@root/core/src/services/analytics-service';
import BlockRenderer from '@root/gatsby-contentful-core/src/components/blocks/block-renderer';
import CoreBlockComponentMap from '@root/gatsby-contentful-core/src/models/core-block-component-map';
import CorePageTemplate from '@root/gatsby-contentful-core/src/components/pages/core-page-template';
import ErrorReportService from '@root/core/src/services/error-report-service';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import buildPathFromSlug from '@root/gatsby-contentful-core/src/utils/build-path-from-slug';
import calculateSectionSpacing from '@root/gatsby-contentful-core/src/utils/calculate-section-spacing';
import { useEffect } from '@root/vendor/react';

export default function CoreBasicPage({
  baseUrl, blockComponentMap, data, footer, location, nav,
}) {
  const basicPage = data.contentfulBasicPage;
  const metadataSlug = AnalyticsService.normalizeForAnalyticsContext(basicPage.publishingMetadata.slug);
  const analyticsContext = `BASIC_PAGE_${metadataSlug}`;
  const blocksWithSectionSpacingData = calculateSectionSpacing(basicPage.blocks, 'BASIC_PAGE');

  useEffect(() => {
    if (data.contentfulBasicPage.publishingMetadata.slug === '/404') {
      ErrorReportService.reportError({
        error: new Error('CMS 404 Page Hit'),
      });
    }
  }, [data.contentfulBasicPage.publishingMetadata.slug]);

  return (
    <CorePageTemplate
      analyticsContext={analyticsContext}
      ctaLink={basicPage.ctaLink}
      ctaText={basicPage.ctaText}
      deIndexPage={basicPage.publishingMetadata.deIndexPage}
      footer={footer}
      includeHotjar={basicPage.publishingMetadata.includeHotjar}
      includeOptimizely={basicPage.publishingMetadata.includeOptimizely}
      isCtaButtonDisabled={basicPage.disableCtaButton}
      isStickyFooterDisabled={basicPage.disableStickyFooter}
      location={location}
      nav={nav}
      pageCanonical={`${baseUrl}${buildPathFromSlug(basicPage.publishingMetadata.slug)}`}
      pageDescription={basicPage.publishingMetadata.pageDescription}
      pageThumbnail={basicPage.publishingMetadata.pageThumbnail?.file?.url}
      pageTitle={basicPage.publishingMetadata.pageTitle}
    >
      {blocksWithSectionSpacingData && blocksWithSectionSpacingData.map((block) => (
        <BlockRenderer
          block={block}
          blockComponentMap={blockComponentMap}
          key={block.id}
          page={basicPage}
        />
      ))}
    </CorePageTemplate>
  );
}

CoreBasicPage.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  blockComponentMap: PropTypes.instanceOf(CoreBlockComponentMap).isRequired,
  data: PropTypes.shape({
    contentfulBasicPage: PropTypes.shape({
      id: PropTypes.string.isRequired,
      publishingMetadata: PropTypes.shape({
        deIndexPage: PropTypes.bool,
        includeHotjar: PropTypes.bool,
        includeOptimizely: PropTypes.bool,
        pageTitle: PropTypes.string.isRequired,
        pageDescription: PropTypes.string.isRequired,
        pageThumbnail: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        slug: PropTypes.string.isRequired,
      }).isRequired,

      ctaLink: PropTypes.string,
      ctaText: PropTypes.string,
      disableCtaButton: PropTypes.bool,
      disableStickyFooter: PropTypes.bool,

      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          content: PropTypes.shape({
            json: PropTypes.object.isRequired,
          }),
          sys: PropTypes.shape({
            contentType: PropTypes.shape({
              sys: PropTypes.shape({
                id: PropTypes.string.isRequired,
              }),
            }),
          }),
        }).isRequired,
      ),
    }).isRequired,
  }).isRequired,
  footer: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  nav: PropTypes.func.isRequired,
};
